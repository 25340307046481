import React from "react";
import { graphql, Link } from "gatsby";
import { Row, Col, Breadcrumb } from "react-bootstrap";

import Layout from "../components/layout";
import ShowcaseProductcard from "../components/showcase-product-card";
import { DisclaimerTooltip } from "../components/disclaimer-tooltip";
import SideNav from "../components/side-nav";

import "./category.module.scss";

class Category extends React.Component {
  render() {
    const products = this.props.data.products;
    const title = this.props.pageContext.title;
    const { frontmatter: description } = this.props.data.descriptions;
    const { bc, earningsTooltip, reviewTooltip } =
      this.props.data.settings.childMarkdownRemark.frontmatter;
    return (
      <Layout title={title}>
        <div className={`category-container`}>
          <Breadcrumb className="pl-5">
            <Link to="/">Home</Link>
            <p className="pr-2 pl-2">/</p>
            <Breadcrumb.Item active>Categories</Breadcrumb.Item>
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb>

          <Row className={`justify-content-center mt-5`}>
            <Col xs="12" lg="9">
              <section id="intro">
                <div className="text-center">
                  <h1>{title} Services of 2024</h1>
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{ __html: description.intro }}
                  ></div>
                </div>
                <div className="text-right">
                  <DisclaimerTooltip disclaimer={earningsTooltip} />
                </div>
                <hr xs="12"></hr>
              </section>
            </Col>
          </Row>

          <Row className={`mt-5 ml-0 mr-0 mr-md-3 ml-md-3`}>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 3, order: 0 }}
              className={`mt-5 mt-lg-0`}
            >
              <section id="sidenav">
                <SideNav categories={this.props.data.categories} />
              </section>
            </Col>

            <Col xs="12" lg="9">
              <section id="top10-cards">
                <Row className="justify-content-center" noGutters="true">
                  {products.edges &&
                    products.edges.map((item, index) => {
                      let { frontmatter: product } = item.node;
                      return (
                        <div
                          className="mb-3 col-12"
                          style={{ maxWidth: "800px" }}
                          key={index}
                        >
                          <ShowcaseProductcard
                            key={index}
                            index={index}
                            product={product}
                            reviewTooltip={reviewTooltip}
                            bc={bc}
                          />
                        </div>
                      );
                    })}
                </Row>
              </section>

              <section id="conclusion" className={`text-center mt-5`}>
                <div>
                  <h2>Why do I need {title} software?</h2>
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{ __html: description.conclusion }}
                  ></div>
                </div>
              </section>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default Category;

export const pageQuery = graphql`
  query ($slug: String!) {
    settings: file(name: { eq: "global" }) {
      childMarkdownRemark {
        frontmatter {
          title
          bc
          earningsTooltip {
            name
            text
          }
          reviewTooltip {
            name
            text
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "category" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
    descriptions: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        intro
        conclusion
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "product" }, category: { eq: $slug } }
      }
      sort: { fields: frontmatter___weight, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            stars
            weight
            logo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            offer {
              text
              url
              color
            }
            showcase {
              description
              highlights {
                point
              }
            }
          }
        }
      }
    }
  }
`;
