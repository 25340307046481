import React from "react";
import { Col, Nav } from "react-bootstrap";
import { Link } from "gatsby";

class SideNav extends React.Component {
  render() {
    let { categories } = this.props;
    return (
      <Nav className={`side-nav`}>
        <Col>
          <h3 className="mb-2">Categories</h3>
          <hr></hr>
          {categories.edges &&
            categories.edges.map((item, index) => {
              return (
                <div key={index}>
                  <Nav.Item>
                    <Link
                      style={{ color: "black" }}
                      to={`/categories/${item.node.frontmatter.slug}`}
                      activeStyle={{ color: "blue" }}
                    >
                      {item.node.frontmatter.title}
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                </div>
              );
            })}
        </Col>
      </Nav>
    );
  }
}

export default SideNav;
