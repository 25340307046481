import React from "react";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import Img from "gatsby-image";
import { Link } from "gatsby";

import { DisclaimerTooltip } from "./disclaimer-tooltip";
import StarRating from "./star-rating";

import "./showcase-product-card.module.scss";

const ShowcaseProductCard = ({ index, product, reviewTooltip, bc }) => {
  const button_color = product.offer.color ? product.offer.color : bc;
  return (
    <Card>
      <Accordion>
        <Card.Body>
          <Row>
            <Col xs="12" sm="4">
              <Button
                size="lg"
                style={{
                  position: "absolute",
                  zIndex: 999,
                  top: "-20px",
                  left: "-6px",
                }}
              >
                {index + 1}
              </Button>
              <Img
                loading="eager"
                fluid={product.logo.childImageSharp.fluid}
                placeholderStyle={{ visibility: "hidden" }}
              />
            </Col>
            <Col xs="12" sm="4">
              <h3>{product.title}</h3>
              <ul>
                {product.showcase.highlights &&
                  product.showcase.highlights.map((highlight, index) => {
                    return <li key={index}>{highlight.point}</li>;
                  })}
              </ul>
            </Col>
            <Col className="text-center" xs="12" sm="4">
              <Button
                size="lg"
                variant="success"
                style={{
                  backgroundColor: button_color,
                  borderColor: button_color,
                }}
                className="col-12"
                href={product.offer.url}
              >
                {product.offer.text}
              </Button>
              <Row className="mt-3 mb-2 justify-content-center">
                <StarRating stars={Number(product.stars)} color={`gold`} />
              </Row>
              <Row className="mt-3 mb-2 justify-content-center">
                <DisclaimerTooltip disclaimer={reviewTooltip} />
              </Row>
            </Col>
          </Row>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            className="float-right mt-3"
          >
            + More Info
          </Accordion.Toggle>
        </Card.Body>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="mt-4">
            <Row>
              <Col xs="12" sm="8">
                <div
                  dangerouslySetInnerHTML={{
                    __html: product.showcase.description,
                  }}
                ></div>
              </Col>
              <Col xs="12" sm="4">
                <Img
                  loading="eager"
                  fluid={product.image.childImageSharp.fluid}
                  placeholderStyle={{ visibility: "hidden" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Link to={`/${product.slug}`}>Read review</Link>
                <a href={product.offer.url} className="float-right">
                  Visit Site >>
                </a>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  );
};

export default ShowcaseProductCard;
